<template>
    <main class="danger">
        <router-view
            class="danger__data"
            :reportDaterange="reportDaterange"
            :compareDaterange="compareDaterange"
            :userRestaurants="userRestaurants"
            :reportRestaurantsKey="reportRestaurantsKey"
            :reportRestaurants="reportRestaurants"
            :reportKey="reportKey"
            :isShowRestaurantsFilter="isShowRestaurantsFilter"
            :minDate="minDate"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "Danger",
        data() {
            return {
                // Данные по опасным операциям есть только с 2021-09-09
                minDate: new Date(2021, 8, 9)
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.danger.compareDaterange,
                isShowRestaurantsFilter: state => state.ui.isShowRestaurantsFilter
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
                "reportKey",
            ])
        }
    }
</script>
